import type {GetStaticProps} from 'next';
import {useTranslation} from 'next-i18next';
import {serverSideTranslations} from 'next-i18next/serverSideTranslations';
import React from 'react';

const WebviewIndex: React.FC = () => {
	const {t} = useTranslation();
	return <div>{t('generic_action_launchdeezer_standalone-features')}</div>;
};

export const getStaticProps: GetStaticProps = async ({locale = ''}) => {
	const translations = await serverSideTranslations(locale, ['common']);
	return {
		props: translations,
	};
};

export default WebviewIndex;
